// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons60042: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { accent, white, primary, background1 },
    },
  } = themeVars;
  return {
    SportTabs: {
      tab: {
        color: '#fff',
        background: background1,
        '&:hover': {
          color: '#fff',
        },
      },
      tabs: {
        padding: [0, margins.xs],
      },
    },
    MobileWidgetCouponView: {
      sportHeader: {
        color: primary,
      },
      sportInnerLink: {
        color: primary,
      },
    },
    WidgetSportMobile: {
      league: {
        color: '#4e5a6c',
      },
    },
    SuspendedBlock: {
      suspendedBlock: {
        color: '#fff',
      },
    },
    Market: {
      root: {
        padding: [0, 5],
        borderTop: 'none',
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        background: accent,
        fontWeight: 'bold',
        '&:hover': {
          background: '#FFB951',
        },
      },
    },
    WidgetSport: {
      sport: {
        padding: [0, margins.lg],
      },
      event: {
        background: '#fff',
      },
    },

    Betbuilder: {
      toggler: {
        '&:hover': {
          background: accent,
        },
      },
      exampleContainer: {
        background: white,
      },
    },
    PrematchEvent: {
      marketRoot: {
        background: `${white} !important`,
      },
    },
  };
};
