// @flow
import { type JSSThemeVariablesType, type $DeepShape } from './JSSThemeDefault';
import { applyOnlyDesktop } from './jss-helpers';

export const JSSThemeDefaultIPools = (themeVars: JSSThemeVariablesType) => {
  const {
    margins,
    borderRadius,
    fontSizes,
    buttons,
    colors: {
      colorTypes: {
        success,
        error,
        primary,
        neutral1,
        neutral2,
        neutral3,
        neutral4,
        white,
        background2,
        background4,
        background7,
        accent,
        text1,
        text2,
        text3,
        black,
      },
    },
  } = themeVars;
  return {
    IPoolsBanners: {
      bannersRoot: {
        padding: '0 15px 10px',
      },
      banner: {
        margin: [margins.xm, 0, 0],
        cursor: 'pointer',
        borderRadius: 10,
        display: 'flex',
        transition: '.3s ease-in',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        textShadow: '0 4px 16px rgba(0,0,0,0.9)',
        position: 'relative',
        '&:hover': {
          color: white,
          transform: 'translateY(-2px)',
        },
        '&:first-child': {
          margin: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
        '&:after': {
          content: `''`,
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 0,
          bottom: 0,
          left: 0,
          borderRadius: 10,
          background: `linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(10, 31, 50, 0) 60%, rgba(10, 31, 50, 0) 100%)`,
        },
      },
      bannerUnvisibleLink: {
        display: 'none',
      },
      bannerImage: {
        width: '100%',
        height: 'auto',
        borderRadius: 10,
      },
      bannerContent: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        left: 15,
        bottom: 10,
      },
      bannerTitle: {
        display: 'block',
        color: white,
        fontSize: fontSizes.lg,
        zIndex: '1',
        position: 'relative',
        fontWeight: 900,
      },
      bannerLink: {
        color: primary,
        fontWeight: 900,
        fontSize: fontSizes.xs,
        position: 'relative',
        zIndex: '2',
        margin: '1px 0 0',
        '&:hover, &:active': {
          color: primary,
        },
      },
      chevron: {
        marginLeft: `${margins.xs}px !important`,
      },
    },
    IPools: {
      upcomingPools: {
        padding: [margins.xm, 0, margins.xs],
        background: background4,
        borderRadius: margins.xm,
        marginTop: margins.xm,
        marginBottom: margins.lg,
      },
      root: {
        background: background4,
        color: white,
        padding: [0, margins.xm, margins.md],
        borderRadius: margins.xm,
        '@media (pointer: coarse)': {
          margin: 0,
          padding: [margins.xm, margins.xm, margins.md],
          borderRadius: 0,
          background: 'transparent',
        },
      },
      howToSection: {
        display: 'flex',
        flexDirection: 'column',
      },
      howToTitle: {
        fontSize: fontSizes.md,
        margin: [0, 0, 5],
        fontWeight: 900,
      },
      howToList: {
        paddingLeft: 15,
        fontWeight: 400,
        margin: [0, 0, 5],
        fontSize: fontSizes.sm,
      },
      howToLinkManual: {
        padding: [margins.md, margins.xm],
        color: white,
        fontSize: fontSizes.md,
        cursor: 'pointer',
        backgroundColor: neutral1,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: [10, 10, 0, 0],
        marginBottom: 2,
        transition: '.3s ease',
        backgroundSize: '36%',
        background:
          'url(https://cdn1.inkabet.pe/images/ipools-src/bg-info-top.svg) no-repeat right bottom',
        '&:hover': {
          transform: 'scale(1.01)',
          backgroundSize: '40%',
          color: 'inherit',
        },
      },
      howToLinkVideo: {
        padding: [margins.sm, margins.xm],
        color: white,
        cursor: 'pointer',
        backgroundColor: neutral1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        fontSize: fontSizes.md,
        fontWeight: 900,
        borderRadius: [0, 0, 10, 10],
        transition: '.3s ease',
        backgroundSize: '36%',
        background:
          'url(https://cdn1.inkabet.pe/images/ipools-src/bg-info-bottom.svg) no-repeat right top',
        '&:hover': {
          transform: 'scale(1.01)',
          backgroundSize: '40%',
          color: 'inherit',
        },
      },
      howToIcon: {
        color: white,
        marginLeft: 10,
      },
      date: {
        minWidth: 65,
        fontSize: fontSizes.xs,
      },
      rootDesktop: {
        background: 'transparent',
        display: 'flex',
        width: '100%',
        maxWidth: '1440px',
        margin: '0 auto',
      },
      leftColumn: {
        width: 300,
        marginLeft: margins.md,
      },
      rightColumn: {
        flex: 2,
        padding: [0, margins.xm, 0, 0],
        width: 'calc(100% - 310px)',
      },
      event: {
        display: 'block',
        backgroundColor: neutral1,
        borderRadius: borderRadius.xl,
        margin: [margins.xm, 0, 0],
        padding: [margins.md, margins.xm],
        color: white,
        cursor: 'pointer',
        background:
          'url(https://cdn1.inkabet.pe/images/ipools-src/trophy-bg.svg) no-repeat right bottom',
        transition: '0.2s ease-in',
        '&:first-child': {
          marginTop: 0,
        },
        '&:hover': {
          transform: 'translateY(-2px)',
          color: 'inherit',
        },
        '&:focus': {
          transform: 'translateY(-2px)',
          color: 'inherit',
        },
      },
      eventSelected: {
        boxShadow: `inset 0px 0px 0px 3px ${white}`,
      },
      topBar: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
      topBarIcon: {
        width: 13,
        height: 13,
        fontSize: fontSizes.lg,
        marginRight: margins.xs,
      },
      topBarIconLive: {
        color: primary,
      },
      eventLive: {
        color: primary,
        fontSize: fontSizes.xs,
      },
      eventHead: {
        paddingBottom: margins.xm,
      },
      winBigJackpotLabel: {
        textAlign: 'left',
        color: primary,
        fontWeight: 500,
        textTransform: 'none',
        fontSize: fontSizes.xs,
        margin: ['auto', 'auto', 2, 0],
        display: 'flex',
      },
      winBigJackpotIcon: {
        width: 11,
        height: 14,
        fontSize: fontSizes.lg,
        marginRight: margins.xs,
      },
      enteredLabel: {
        backgroundColor: 'transparent',
        color: primary,
        borderRadius: margins.xs,
        boxShadow: `0px 0px 0px 1.5px ${primary}`,
        fontSize: fontSizes.xs,
        padding: [0, margins.xs],
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginLeft: 'auto',
        maxHeight: 15,
        minWidth: 131,
      },
      eventName: {
        fontWeight: 900,
        fontSize: fontSizes.lg,
        flex: 3,
        marginTop: margins.xs,
      },
      eventTimeLeft: {
        fontSize: fontSizes.sm,
        flex: 1,
      },
      eventInfo: {
        display: 'flex',
        justifyContent: 'flex-end',
        fontWeight: 'bold',
        fontSize: fontSizes.xxl,
        textAlign: 'end',
      },
      eventInfoValue: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        lineHeight: 1.3,
      },
      eventInfoLabel: {
        marginTop: 0,
        fontSize: fontSizes.xs,
        marginBlockEnd: 0,
        fontWeight: 'normal',
        '&.isBigJackpot': {
          color: primary,
        },
      },
      errors: {
        padding: [margins.lg, margins.md],
        fontWeight: 900,
        textAlign: 'center',
        opacity: 0.5,
        fontSize: 28,
        margin: ['10%', 'auto', '10%'],
      },
    },
    IPoolsSettled: {
      rootSettled: {
        background: background4,
        color: white,
        padding: [0, margins.xm, margins.md],
        borderRadius: margins.xm,
        '@media (pointer: coarse)': {
          margin: 0,
          padding: [margins.xm, margins.xm, margins.md],
          borderRadius: 0,
          background: 'transparent',
        },
      },
      event: {
        '@media (pointer: coarse)': {
          backgroundColor: background4,
        },
        display: 'block',
        backgroundColor: background7,
        borderRadius: borderRadius.xl,
        margin: [margins.xm, 0, 0],
        padding: [margins.md, margins.xm],
        color: white,
        cursor: 'pointer',
        background:
          'url(https://cdn1.inkabet.pe/images/ipools-src/trophy-bg-lite.svg) no-repeat right bottom',
        '&:first-child': {
          marginTop: 0,
        },
        '&:hover': {
          transform: 'translateY(-2px)',
          color: 'inherit',
        },
      },
      eventSelected: {
        boxShadow: `inset 0px 0px 0px 3px ${primary}`,
      },
      eventHead: {
        paddingBottom: margins.xm,
      },
      winBigJackpotLabel: {
        textAlign: 'left',
        color: primary,
        fontWeight: 500,
        textTransform: 'none',
        fontSize: fontSizes.xs,
        margin: ['auto', 'auto', 2, 0],
        display: 'flex',
      },
      winBigJackpotIcon: {
        width: 11,
        height: 14,
        fontSize: fontSizes.lg,
        marginRight: margins.xs,
      },
      eventName: {
        fontWeight: 900,
        fontSize: fontSizes.lg,
        flex: 3,
      },
      eventInfo: {
        display: 'flex',
        justifyContent: 'flex-end',
        fontWeight: 'bold',
        fontSize: fontSizes.xxl,
        textAlign: 'end',
        opacity: 0.5,
      },
      eventInfoValue: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        lineHeight: 1.3,
      },
      eventInfoLabel: {
        marginTop: 0,
        fontSize: fontSizes.xs,
        marginBlockEnd: 0,
        fontWeight: 'normal',
        '&.isBigJackpot': {
          color: primary,
        },
      },
      errors: {
        padding: [margins.lg, margins.md],
        fontWeight: 900,
        textAlign: 'center',
        opacity: 0.5,
        fontSize: 28,
        margin: ['10%', 'auto', '10%'],
      },
      errorNoPool: {
        padding: [margins.lg, margins.md],
        fontWeight: 900,
        textAlign: 'center',
        opacity: 0.5,
        fontSize: 28,
        margin: ['10%', 'auto', '10%'],
      },
      rootNoPrematch: {
        height: '100%',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      errorNoPrematchPool: {
        padding: [margins.lg, margins.md],
        margin: ['10%', 'auto', '10%'],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 900,
        opacity: 0.5,
        fontSize: 28,
        textAlign: 'center',
      },
      link: {
        ...buttons.linkDefault,
        display: 'block',
        fontSize: fontSizes.md,
        marginBottom: margins.lg,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
          },
        }),
      },
      settledRoot: {
        '@media (pointer: coarse)': {
          background: 'transparent',
          margin: [margins.md, 0, margins.lg],
        },
        background: background4,
        color: white,
        padding: [margins.xs, 0, margins.xs],
        margin: [margins.xs, 0, margins.xs],
        borderRadius: borderRadius.xl,
        '&:hover': {
          color: 'rgba(254, 254, 254, 0.8)',
        },
      },
      settledToggler: {
        width: 'fit-content',
        cursor: 'pointer',
        padding: [0, 0, 0, margins.xm],
        margin: [0, 0, margins.md],
        fontWeight: 'bold',
        transition: '.2s ease-in',
        '& i.fa': {
          transition: 'none',
        },
        '@media (pointer: coarse)': {
          margin: 0,
        },
      },
      settledChevron: {
        marginLeft: `${margins.md}px !important`,
      },
    },
    Entries: {
      seatsWrap: {
        padding: [0, 0, margins.xm, margins.xm],
      },
      seatsTitle: {
        padding: [margins.md, 0],
        color: white,
        fontWeight: 'bold',
        fontSize: fontSizes.xl,
        display: 'block',
        margin: 0,
        '@media (pointer: coarse)': {
          fontSize: fontSizes.md,
        },
      },
      seatsScrollWrap: {
        height: 50,
        overflow: 'hidden',
      },
      seatsScroll: {
        height: 80,
        overflowX: 'scroll',
        boxSizing: 'content-box',
        '-webkit-overflow-scrolling': 'touch',
      },
      seats: {
        margin: [0, -margins.xs],
        whiteSpace: 'nowrap',
        display: 'inline-flex',
      },
      newSeat: {
        height: 50,
        width: 50,
        borderRadius: borderRadius.xl,
        textAlign: 'center',
        display: 'inline-block',
        fontWeight: 'bold',
        margin: [0, margins.xs],
        transition: '.3s ease-in',
        '& > span': {
          fontSize: '20px',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center',
        },
        color: '#7a5721',
        border: `5px solid #7a5721`,
        '&.active': {
          '&:hover': {
            border: `5px solid ${neutral4}`,
            color: neutral4,
          },
          cursor: 'pointer',
          border: `5px solid ${primary}`,
          color: primary,
        },
      },
      seat: {
        height: 50,
        width: 50,
        display: 'inline-block',
        padding: [margins.xs, margins.md],
        margin: [0, margins.xs],
        background: background4,
        fontWeight: 'bold',
        borderRadius: borderRadius.xl,
        transition: '.3s ease-in',
        border: 0,
        position: 'relative',
        cursor: 'pointer',
        '& > div': {
          top: '17%',
          left: '17%',
          position: 'absolute',
          fontWeight: 'bold',
        },
        '&:hover': {
          background: background2,
        },
        '&.active': {
          background: primary,
          cursor: 'default',
        },
        '@media (pointer: coarse)': {
          background: neutral1,
        },
      },
    },
    EventHeader: {
      places: {
        margin: [0, 0, '30px', margins.lg],
      },
      prizeFundValue: {
        fontSize: '50px',
        fontWeight: 800,
        lineHeight: '20px',
        margin: '0 0 30px',
        whiteSpace: 'nowrap',
      },
      placesRoot: {
        marginLeft: 'auto',
        '@media (pointer: coarse)': {
          margin: [margins.xm, 0, 0],
        },
      },
      prizeFundRoot: {
        color: 'white',
        fontWeight: 'bold',
        '@media (pointer: coarse)': {
          padding: [margins.md, margins.md, 0],
        },
      },
      prizeFundLabel: {
        fontSize: fontSizes.lg,
      },
      wrapBars: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        margin: 0,
        background: 'rgba(71, 71, 71, .75)',
        padding: 0,
        borderRadius: borderRadius.xl,
        minWidth: 200,
        color: '#fff',
        '@media (pointer: coarse)': {
          background: 'transparent',
          padding: 0,
        },
      },
      barContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: [0, margins.xs],
        margin: [margins.md, 0],
        '@media (pointer: coarse)': {
          flex: 'none',
          margin: 0,
          '&:first-child': {
            marginLeft: 0,
          },
          '&:last-child': {
            marginRight: 0,
          },
        },
        '&:first-child': {
          marginLeft: margins.md,
        },
        '&:last-child': {
          marginRight: margins.md,
        },
      },
      description: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0,
        fontSize: fontSizes.sm,
        '@media (pointer: coarse)': {
          marginTop: margins.xs,
        },
      },
      barContent: {
        alignItems: 'flex-end',
        justifyContent: 'center',
        display: 'flex',
        borderRadius: 3,
        padding: margins.xs,
        fontWeight: 'bold',
        color: 'white',
        fontSize: 15,
        '@media (pointer: coarse)': {
          padding: ['3px', margins.xs, margins.xs],
        },
      },
      barMoney: {
        fontSize: fontSizes.sm,
        fontWeight: 900,
        textAlign: 'center',
      },
      subHeading: {
        paddingTop: margins.xm,
        display: 'flex',
        color: white,
        '@media (pointer: coarse)': {
          justifyContent: 'space-between',
        },
      },
      subHeadingBlock: {
        paddingRight: margins.xl,
        '@media (pointer: coarse)': {
          paddingRight: margins.md,
        },
      },
      subHeadingValue: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        '@media screen and (max-width: 720px)': {
          fontSize: fontSizes.lg,
        },
      },
      subHeadingValueLabel: {
        margin: 0,
        padding: 0,
        opacity: 0.7,
        '@media screen and (max-width: 720px)': {
          fontSize: fontSizes.sm,
        },
      },
      placesMobile: {
        margin: 0,
        background: '#2b2b2b9e',
        padding: [0, margins.md, margins.md],
        borderRadius: borderRadius.xl,
        '@media (pointer: coarse)': {
          padding: [0, margins.xs, margins.xm],
        },
      },
      prizeFundValueMobile: {
        fontSize: 32,
        display: 'inline-block',
        margin: [0, '7px', 0, 0],
      },
    },
    QuestionHeader: {
      live: {
        questionHeader: {
          display: 'flex',
          margin: [0, margins.xm],
          opacity: '.6',
          '@media screen and (min-width: 1440px)': {
            margin: [margins.xs, margins.md],
          },
          '@media (pointer: coarse)': {
            marginBottom: margins.md,
            alignItems: 'center',
          },
        },
        eventTitle: {
          flex: 1,
          fontSize: fontSizes.sm,
        },
        questionColumnsLiveOrEnded: {
          marginRight: '30px !important',
          '@media (pointer: coarse)': {
            marginRight: '34px !important',
          },
        },
        questionColumns: {
          marginRight: 0,
          '@media (pointer: coarse)': {
            display: 'flex',
            alignItems: 'center',
          },
        },
        questionColumn: {
          textAlign: 'center',
          fontSize: fontSizes.sm,
          width: 65,
          marginLeft: margins.xs,
          display: 'inline-block',
          '@media screen and (max-width: 720px)': {
            width: 50,
          },
          '@media screen and (min-width: 1440px)': {
            marginLeft: margins.md,
            width: 82,
          },
        },
        questionColumnElPlus: {
          maxWidth: 44,
          '@media (pointer: coarse)': {
            maxWidth: 34,
          },
        },
      },
      settled: {
        questionHeader: {
          display: 'flex',
          margin: [0, margins.xm],
          opacity: '.6',
          '@media (pointer: coarse)': {
            marginBottom: margins.md,
          },
        },
        eventTitle: {
          flex: 1,
          fontSize: fontSizes.sm,
        },
        questionColumnsLiveOrEnded: {},
        questionColumns: {
          marginRight: 29,
          '@media screen and (max-width: 375px)': {
            marginRight: 18,
          },
          '@media screen and (max-width: 720px)': {
            marginRight: 29,
          },
          '@media (pointer: coarse)': {
            marginRight: 29,
          },
        },
        questionColumn: {
          textAlign: 'center',
          fontSize: fontSizes.sm,
          width: 65,
          marginLeft: margins.xs,
          display: 'inline-block',
          '@media screen and (max-width: 720px)': {
            width: 50,
          },
          '@media screen and (min-width: 1440px)': {
            marginLeft: margins.md,
            width: 82,
          },
        },
        questionColumnElPlus: {},
      },
    },
    Question: {
      live: {
        correctChoice: {},
        question: {
          display: 'flex',
          margin: 0,
          padding: [margins.md, margins.xm],
          alignItems: 'center',
          transition: '.3s ease-in',
          '@media (pointer: coarse)': {
            borderRadius: 0,
            padding: [0, margins.xm],
          },
          '@media screen and (max-width: 720px)': {
            margin: [0, 0, margins.lg],
          },
        },
        questionMultiChoice: {
          '@media screen and (max-width: 1020px)': {
            flexDirection: 'column',
            alignItems: 'center',
          },
        },
        questionMixedWrap: {
          '@media screen and (max-width: 720px)': {
            flexDirection: 'column',
            margin: [0, 0, margins.lg],
          },
        },
        questionBanker: {
          background: '#3333337d',
          borderRadius: borderRadius.lg,
          padding: margins.xm,
        },
        questionMultiChoiceBanker: {},
        questionTeams: {
          flex: 1,
          minWidth: 115,
          marginRight: margins.xs,
          display: 'flex',
          flexDirection: 'column',
          '@media screen and (min-width: 1440px)': {
            flexDirection: 'row',
          },
        },
        questionTime: {
          fontSize: fontSizes.xs,
          order: 3,
          '@media screen and (max-width: 720px)': {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'space-between',
            height: 14,
            marginBottom: margins.xs,
          },
          '@media screen and (min-width: 1440px)': {
            alignSelf: 'center',
          },
        },
        questionLeagueDesktop: {
          fontSize: fontSizes.xs,
          display: 'none',
          '@media screen and (min-width: 1440px)': {
            display: 'block',
          },
        },
        dateAndBanker: {
          marginTop: 2,
          '@media (pointer: coarse)': {
            marginTop: 4,
          },
        },
        bankerLabel: {
          color: primary,
          fontSize: fontSizes.xs,
          display: 'inline-block',
          fontWeight: 'bold',
          marginLeft: margins.xs,
          textTransform: 'uppercase',
        },
        questionLeagueMobile: {
          fontSize: fontSizes.xs,
          order: 1,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'space-between',
          minHeight: 14,
          '@media screen and (min-width: 1440px)': {
            display: 'none',
          },
          '@media (pointer: coarse)': {
            marginBottom: 2,
          },
        },
        teamWrapper: {
          flexBasis: '45%',
          order: 2,
          '@media screen and (min-width: 1440px)': {
            paddingRight: margins.xl,
          },
        },
        questionTeam: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '@media screen and (min-width: 1440px)': {
            order: 1,
          },
        },
        choices: {
          display: 'flex',
        },
        choice: {
          background: primary,
          cursor: 'pointer',
          borderRadius: borderRadius.md,
          border: 0,
          marginLeft: margins.xs,
          width: 92,
          height: 55,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          transition: '.3s ease-in',
          fontSize: '12px',
          '@media screen and (max-width: 720px)': {
            width: 50,
          },
          '@media screen and (min-width: 1440px)': {
            width: 82,
            height: 38,
            marginLeft: margins.md,
          },
          '&:first-child': {
            marginLeft: 0,
          },
          '&.active': {
            background: accent,
            transition: 'all 0.4s',
          },
        },
        choiceHoverable: {
          '@media not all and (pointer: coarse)': {
            '&:hover': {
              background: neutral3,
            },
          },
        },
        score: {
          fontWeight: 'bold',
          textAlign: 'center',
          position: 'absolute',
        },
        column: {
          opacity: 0.3,
          display: 'block',
          '&.active': {
            opacity: 0.7,
            color: white,
          },
        },
        scoreValue: {
          display: 'block',
          '&.small': {
            fontSize: fontSizes.xxs,
          },
          '&.active': {
            color: white,
          },
        },
        x2Button: {
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          padding: [0, margins.xm],
          height: 'auto',
          margin: [0, 0, 0, margins.xs],
          fontWeight: 'bold',
          fontSize: fontSizes.sm,
          background: neutral1,
          borderRadius: 3,
          transition: '.2s ease-in',
          '&.active': {
            color: 'white !important',
            background: `${accent} !important`,
          },
          cursor: 'pointer',
          '&:hover': {
            background: background2,
            color: primary,
          },
          '@media screen and (min-width: 1440px)': {
            height: 40,
            margin: [0, 0, 0, margins.md],
          },
          '@media (pointer: coarse)': {
            background: neutral2,
            color: text2,
            margin: [0, 0, 0, margins.xs],
            padding: [0, margins.md],
            '&:hover': {
              background: neutral2,
              color: text2,
            },
          },
        },
        x2ButtonHidden: {
          opacity: 0,
        },
        resultLabel: {
          marginLeft: margins.md,
          width: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (pointer: coarse)': {
            marginLeft: margins.xs,
            width: 29,
          },
        },
        resultLabelCheck: {
          color: success,
        },
        resultLabelWrong: {
          color: error,
        },
        x2ButtonMixed: {
          '@media screen and (max-width: 720px)': {
            height: 44,
          },
        },
        choicesMixed: {
          '@media screen and (max-width: 720px)': {
            width: '100%',
            height: 44,
            justifyContent: 'flex-end',
            alignItems: 'center',
          },
          '@media (pointer: coarse)': {
            width: '100%',
          },
        },
        mixedAnswer: {
          width: 200,
          '@media screen and (max-width: 720px)': {
            width: '100%',
          },
        },
        mixedTwoOptions: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        mixedTwoOptionsChoiceScore: {
          fontWeight: 'bold',
        },
        selectInput: {
          width: 200,
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          position: 'relative',
          height: 38,
          '& select': {
            border: 0,
            padding: '0px 37px 0px 15px',
            margin: 0,
            borderRadius: 4,
            color: 'rgba(255, 255, 255, 0.6)',
            width: '100%',
            height: '100%',
            position: 'relative',
            background: background2,
            '-moz-appearance': 'none',
            '-webkit-appearance': 'none',
            appearance: 'none',
            cursor: 'pointer',
          },
          '&.active': {
            '& select': {
              transition: '.3s ease-in',
              background: primary,
              color: white,
            },
          },
          '@media screen and (max-width: 720px)': {
            width: '100%',
          },
        },
        dropdownIcon: {
          position: 'absolute',
          pointerEvents: 'none',
          right: 18,
          '&.active': {
            color: black,
          },
        },
        x2buttonMultiChoice: {
          height: 38,
          padding: '0 10px',
        },
        questionDescriptionMultiChoice: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media screen and (max-width: 1020px)': {
            width: '100%',
            margin: 0,
          },
        },
        descriptionMultiChoice: {
          overflow: 'hidden',
          maxWidth: 500,
          '@media screen and (max-width: 1020px)': {
            maxWidth: '90%',
          },
        },
        infoDescriptionWrapperMultiChoice: {
          position: 'relative',
          '&:before': {
            display: 'block',
            position: 'absolute',
            visibility: 'hidden',
            opacity: 0,
            transition: 'opacity .7s',
            color: '#403f3c',
            backgroundColor: '#fefefe',
            content: '""',
            width: '10px',
            height: '10px',
            left: '7px',
            top: '23px',
            transform: 'translateX(-6px) rotate(45deg)',
          },
          '&:hover, &:active': {
            '& $infoDescriptionMultiChoice': {
              opacity: 1,
              visibility: 'visible',
            },
          },
          '&:hover:before, &:hover:after': {
            opacity: 1,
            visibility: 'visible',
          },
          '@media screen and (max-width: 1020px)': {
            width: 29,
            display: 'flex',
            justifyContent: 'center',
            margin: 0,
          },
        },
        choicesMultiChoice: {
          alignItems: 'center',
          '@media screen and (max-width: 1020px)': {
            width: '100%',
            justifyContent: 'space-between',
          },
          '@media screen and (max-width: 720px)': {
            height: 44,
          },
        },
        infoDescriptionMultiChoice: {
          position: 'absolute',
          top: '27px',
          left: '-27px',
          zIndex: 1000,
          width: 'fit-content',
          minWidth: 150,
          padding: '10px',
          opacity: 0,
          visibility: 'hidden',
          overflow: 'hidden',
          background: '#FFF',
          color: '#000',
          fontSize: '0.8em',
          borderRadius: '5px',
          transition: 'opacity .7s',
          '@media (pointer: coarse)': {
            left: -125,
          },
        },
        rangeInput: {
          width: 200,
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          marginTop: '10%',
          '& input[type=range]': {
            width: '100%',
            appearance: 'none',
            background: 'transparent',
            margin: 0,
            zIndex: 1,
          },
          '& input[type=range]::-webkit-slider-thumb': {
            appearance: 'none',
            border: 0,
            background: primary,
            width: 20,
            height: 20,
            borderRadius: 10,
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            marginTop: -8,
          },
          '& input[type=range]:focus': {
            outline: 'none',
          },
          '& input[type=range]::-webkit-slider-runnable-track': {
            width: '100%',
            height: 5,
            cursor: 'pointer',
            background: text3,
            borderRadius: 2.5,
            border: 0,
          },
          '&.active': {
            '& input[type=range]::-webkit-slider-thumb': {
              background: primary,
            },
          },
          '@media screen and (max-width: 720px)': {
            width: '100%',
          },
        },
        rangeInputValue: {
          textAlign: 'center',
          position: 'absolute',
          bottom: 15,
          fontSize: 14,
          color: text2,
          left: 0,
          right: 0,
          '&.active': {
            color: text2,
          },
        },
        rangeInputMidLine: {
          position: 'absolute',
          height: 10,
          width: 1,
          backgroundColor: primary,
          left: '50%',
          bottom: 0,
        },
      },
      settled: {
        correctChoice: {},
        question: {
          display: 'flex',
          padding: [margins.md, margins.xm],
          margin: 0,
          alignItems: 'center',
          '@media screen and (min-width: 1440px)': {
            margin: [0, 0, margins.xm],
          },
          '@media (pointer: coarse)': {
            borderRadius: 0,
            padding: [0, margins.xm],
          },
          '@media screen and (max-width: 720px)': {
            margin: [0, 0, margins.lg],
          },
        },
        questionMultiChoice: {
          '@media screen and (max-width: 1020px)': {
            flexDirection: 'column',
            alignItems: 'center',
          },
        },
        questionMixedWrap: {
          '@media screen and (max-width: 720px)': {
            flexDirection: 'column',
            margin: [0, 0, margins.lg],
          },
        },
        questionBanker: {},
        questionMultiChoiceBanker: {},
        questionTeams: {
          flex: 1,
          minWidth: 115,
          marginRight: margins.xs,
          display: 'flex',
          flexDirection: 'column',
          '@media screen and (min-width: 1440px)': {
            flexDirection: 'row',
          },
        },
        questionTime: {
          fontSize: fontSizes.xs,
          order: 3,
          '@media screen and (max-width: 720px)': {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'space-between',
            height: 14,
            marginBottom: margins.xs,
          },
          '@media screen and (min-width: 1440px)': {
            alignSelf: 'center',
          },
        },
        questionLeagueDesktop: {
          fontSize: fontSizes.xs,
          display: 'none',
          '@media screen and (min-width: 1440px)': {
            display: 'block',
          },
        },
        dateAndBanker: {
          marginTop: 2,
          '@media (pointer: coarse)': {
            marginTop: 4,
          },
        },
        bankerLabel: {
          color: primary,
          fontSize: fontSizes.xs,
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        questionLeagueMobile: {
          fontSize: fontSizes.xs,
          order: 1,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'space-between',
          minHeight: 14,
          marginBottom: margins.xs,
          '@media screen and (min-width: 1440px)': {
            display: 'none',
          },
        },
        teamWrapper: {
          flexBasis: '45%',
          order: 2,
          '@media screen and (min-width: 1440px)': {
            paddingRight: margins.xl,
          },
        },
        questionTeam: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '@media screen and (min-width: 1440px)': {
            order: 1,
          },
        },
        choices: {
          display: 'flex',
        },
        choice: {
          background: primary,
          cursor: 'pointer',
          borderRadius: borderRadius.md,
          border: 0,
          marginLeft: margins.xs,
          width: 92,
          height: 55,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          transition: '.3s ease-in',
          fontSize: '12px',
          '@media screen and (max-width: 720px)': {
            width: 50,
          },
          '@media screen and (min-width: 1440px)': {
            width: 82,
            height: 38,
            marginLeft: margins.md,
          },
          '&:first-child': {
            marginLeft: 0,
          },
          '&.active': {
            background: accent,
            transition: 'all 0.4s',
            '&:hover': {
              background: accent,
            },
          },
        },
        choiceHoverable: {},
        score: {
          fontWeight: 'bold',
          textAlign: 'center',
          position: 'absolute',
        },
        column: {
          opacity: 0.3,
          display: 'block',
          '&.active': {
            opacity: 0.7,
            color: white,
          },
        },
        scoreValue: {
          display: 'block',
          '&.small': {
            fontSize: fontSizes.xxs,
          },
          '&.active': {
            color: white,
          },
        },
        x2Button: {
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          padding: [0, margins.xm],
          height: 50,
          fontWeight: 'bold',
          fontSize: fontSizes.sm,
          opacity: 0.5,
          '&.active': {
            color: primary,
            opacity: 1,
          },
          cursor: 'pointer',
        },
        resultLabel: {
          marginLeft: margins.md,
          width: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '@media (pointer: coarse)': {
            marginLeft: margins.xs,
            width: 29,
          },
        },
        resultLabelCheck: {
          color: success,
        },
        resultLabelWrong: {
          color: error,
        },
        x2ButtonHidden: {
          opacity: 0,
        },
        x2ButtonMixed: {},
        choicesMixed: {},
        mixedAnswer: {
          width: 200,
          '@media screen and (max-width: 720px)': {
            width: '100%',
          },
        },
        mixedTwoOptions: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        mixedTwoOptionsChoiceScore: {
          fontWeight: 'bold',
        },
        selectInput: {
          width: 200,
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          position: 'relative',
          height: 38,
          '& select': {
            border: 0,
            padding: '0px 37px 0px 15px',
            margin: 0,
            borderRadius: 4,
            color: 'rgba(255, 255, 255, 0.6)',
            width: '100%',
            height: '100%',
            position: 'relative',
            background: background2,
            '-moz-appearance': 'none',
            '-webkit-appearance': 'none',
            appearance: 'none',
          },
          '&.active': {
            '& select': {
              transition: '.3s ease-in',
              background: primary,
              color: white,
            },
          },
          '@media screen and (max-width: 720px)': {
            width: '100%',
          },
        },
        dropdownIcon: {
          position: 'absolute',
          pointerEvents: 'none',
          right: 18,
          '&.active': {
            color: black,
          },
        },
        x2buttonMultiChoice: {
          height: 38,
          padding: '0 10px',
        },
        questionDescriptionMultiChoice: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media screen and (max-width: 1020px)': {
            width: '100%',
            margin: 0,
          },
        },
        descriptionMultiChoice: {
          overflow: 'hidden',
          maxWidth: 500,
          '@media screen and (max-width: 1020px)': {
            maxWidth: '90%',
          },
        },
        infoDescriptionWrapperMultiChoice: {
          position: 'relative',
          '&:before': {
            display: 'block',
            position: 'absolute',
            visibility: 'hidden',
            opacity: 0,
            transition: 'opacity .7s',
            color: '#403f3c',
            backgroundColor: '#fefefe',
            content: '""',
            width: '10px',
            height: '10px',
            left: '7px',
            top: '23px',
            transform: 'translateX(-6px) rotate(45deg)',
          },
          '&:hover, &:active': {
            '& $infoDescriptionMultiChoice': {
              opacity: 1,
              visibility: 'visible',
            },
          },
          '&:hover:before, &:hover:after': {
            opacity: 1,
            visibility: 'visible',
          },
          '@media screen and (max-width: 1020px)': {
            width: 29,
            display: 'flex',
            justifyContent: 'center',
            margin: 0,
          },
        },
        choicesMultiChoice: {
          alignItems: 'center',
          '@media screen and (max-width: 1020px)': {
            width: '100%',
            justifyContent: 'space-between',
          },
          '@media screen and (max-width: 720px)': {
            height: 44,
          },
        },
        infoDescriptionMultiChoice: {
          position: 'absolute',
          top: '27px',
          left: '-27px',
          zIndex: 1000,
          width: 'fit-content',
          minWidth: 150,
          padding: '10px',
          opacity: 0,
          visibility: 'hidden',
          overflow: 'hidden',
          background: '#FFF',
          color: '#000',
          fontSize: '0.8em',
          borderRadius: '5px',
          boxSizing: 'border-box',
          transition: 'opacity .7s',
          '@media (pointer: coarse)': {
            left: -125,
          },
        },
        rangeInput: {
          width: 200,
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          marginTop: '10%',
          '& input[type=range]': {
            width: '100%',
            appearance: 'none',
            background: 'transparent',
            margin: 0,
            zIndex: 1,
          },
          '& input[type=range]::-webkit-slider-thumb': {
            appearance: 'none',
            border: 0,
            background: primary,
            width: 20,
            height: 20,
            borderRadius: 10,
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            marginTop: -8,
          },
          '& input[type=range]:focus': {
            outline: 'none',
          },
          '& input[type=range]::-webkit-slider-runnable-track': {
            width: '100%',
            height: 5,
            cursor: 'pointer',
            background: text3,
            borderRadius: 2.5,
            border: 0,
          },
          '@media screen and (max-width: 720px)': {
            width: '100%',
          },
        },
        rangeInputValue: {
          textAlign: 'center',
          position: 'absolute',
          bottom: 15,
          fontSize: 14,
          color: text2,
          left: 0,
          right: 0,
        },
        rangeInputMidLine: {
          position: 'absolute',
          height: 10,
          width: 1,
          backgroundColor: primary,
          left: '50%',
          bottom: 0,
        },
      },
    },
    IPoolsEvent: {
      settledPoolMark: {},
      rootEventHeaderSettled: {},
      rootEventHeaderPic: {
        background:
          'url(https://cdn1.inkabet.pe/images/ipools-src/bg_prize.png) no-repeat 100% 170px',
        backgroundSize: 315,
        '@media (pointer: coarse)': {
          background: 'none',
        },
        '@media screen and (min-width: 1440px)': {
          background:
            'url(https://cdn1.inkabet.pe/images/ipools-src/bg_prize.png) no-repeat 100% 110px',
          backgroundSize: 315,
        },
      },
      eventTotals: {
        width: 300,
        '@media (pointer: coarse)': {
          width: '100%',
        },
      },
      root: {
        '@media (pointer: coarse)': {
          background: background4,
        },
      },
      showAllToggler: {
        margin: `${margins.lg}px 0 ${margins.md}px`,
        fontWeight: 'bold',
      },
      showAllChevron: {
        margin: `0 0 0 ${margins.xs}px !important`,
      },
      backLink: {
        padding: [margins.xm, margins.md, 0],
        lineHeight: 2,
        cursor: 'pointer',
        color: white,
        fontWeight: 'bold',
        display: 'inline-block',
      },
      rootEvent: {
        background: 'transparent',
        '@media (pointer: coarse)': {
          background: background4,
        },
      },
      EventTotalsTabs: {
        overflow: 'hidden',
      },
      rootEventHeader: {
        padding: [0, margins.xm],
        display: 'flex',
      },
      EventHeader: {
        padding: [margins.xm, 0, 0],
        '@media (pointer: coarse)': {
          padding: 0,
        },
      },
      questionLeftCol: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '@media screen and (max-width: 720px)': {
          order: 2,
        },
        '@media (pointer: coarse)': {
          padding: [margins.md, 0, 0],
        },
      },
      questionRightCol: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        '@media screen and (max-width: 720px)': {
          order: 1,
          width: '100%',
          padding: [0, margins.xm, 0, 0],
        },
      },
      questionsList: {},
      questionsWrapper: {
        margin: [0, 0, margins.lg, margins.xm],
        padding: [30, margins.xm],
        background: white,
        borderRadius: margins.xm,
        display: 'flex',
        flexDirection: 'row',
        '@media screen and (max-width: 720px)': {
          flexDirection: 'column',
          margin: '0px 10px 20px 10px',
          width: 'auto !important',
        },
        '@media (pointer: coarse)': {
          margin: [0, 0, margins.lg],
          borderRadius: [margins.xm, margins.xm, 0, 0],
          padding: 0,
          display: 'block',
        },
      },
      questions: {
        padding: [margins.md, margins.md, margins.md, margins.xs],
        background: background4,
        borderRadius: margins.xm,
        display: 'flex',
        flexDirection: 'row',
        margin: [0, 0, margins.lg, margins.xm],
        '@media screen and (max-width: 720px)': {
          padding: [0, 0, margins.sm, 0],
          flexDirection: 'column',
        },
        '@media (pointer: coarse)': {
          background: neutral1,
          borderRadius: [margins.xm, margins.xm, 0, 0],
          padding: [0, margins.xm],
          margin: 0,
        },
      },
    },
    IPoolsSettledEvent: {
      EventHeader: {},
      root: {},
      EventTotalsTabs: {
        overflow: 'hidden',
      },
      showAllToggler: {
        margin: `${margins.lg}px 0 ${margins.md}px`,
        fontWeight: 'bold',
      },
      showAllChevron: {
        margin: `0 0 0 ${margins.xs}px !important`,
      },
      backLink: {
        padding: [margins.xm, margins.md, 0],
        lineHeight: 2,
        cursor: 'pointer',
        color: white,
        fontWeight: 'bold',
        display: 'inline-block',
      },
      rootEvent: {
        background: 'transparent',
        '@media (pointer: coarse)': {
          background: background4,
        },
      },
      rootEventHeader: {
        padding: [0, margins.xm],
        display: 'flex',
        flexDirection: 'column',
      },
      rootEventHeaderSettled: {
        opacity: 0.5,
      },
      rootEventHeaderPic: {
        background:
          'url(https://cdn1.inkabet.pe/images/ipools-src/bg_prize.png) no-repeat 100% 40px',
        backgroundSize: 315,
        '@media (pointer: coarse)': {
          background: 'none',
        },
      },
      settledPoolMark: {
        padding: [margins.xs, margins.xm],
        '@media screen and (min-width: 720px)': {
          paddingTop: margins.xm,
          paddingBottom: 0,
        },
      },
      questionLeftCol: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '@media screen and (max-width: 720px)': {
          order: 2,
        },
        '@media (pointer: coarse)': {
          padding: [margins.md, 0, 0],
        },
      },
      questionRightCol: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        '@media screen and (max-width: 720px)': {
          order: 1,
          width: '100%',
          padding: [0, margins.xm, 0, 0],
        },
      },
      questionsList: {},
      questionsWrapper: {
        margin: [0, 0, margins.lg, margins.xm],
        padding: [30, margins.xm],
        borderRadius: margins.xm,
        display: 'flex',
        flexDirection: 'row',
        background: white,
        '@media screen and (max-width: 720px)': {
          padding: 0,
          flexDirection: 'column',
          margin: '0px 10px 20px 10px',
        },
        '@media (pointer: coarse)': {
          borderRadius: [margins.xm, margins.xm, 0, 0],
          margin: [0, 0, margins.lg],
          padding: 0,
        },
      },
      eventTotals: {
        width: 300,
        '@media (pointer: coarse)': {
          width: '100%',
        },
      },
      questions: {
        padding: [margins.md, margins.xm],
        background: background4,
        borderRadius: margins.xm,
        display: 'flex',
        flexDirection: 'row',
        margin: [0, margins.md, margins.lg],
        '@media screen and (max-width: 720px)': {
          padding: [0, 0, margins.sm, margins.xm],
          flexDirection: 'column',
        },
        '@media (pointer: coarse)': {
          background: neutral1,
          borderRadius: [margins.xm, margins.xm, 0, 0],
          margin: [0, 0, margins.lg],
        },
      },
    },
    IPoolsSubmit: {
      rootMobile: {
        left: 0,
        position: 'fixed',
        bottom: 0,
        background: primary,
        width: '100%',
        padding: margins.xm,
        zIndex: 1,
        '@media screen and (max-width: 720px)': {
          borderRadius: [margins.sm, margins.sm, 0, 0],
        },
      },
      rootDesktop: {
        background: primary,
        borderRadius: borderRadius.xl,
        margin: margins.md,
        padding: [margins.md, margins.xm, margins.xm, margins.xm],
        color: white,
        height: 'auto',
      },
      totalStakeDesktop: {
        marginBottom: margins.md,
      },
      choiceHeading: {
        padding: 0,
        margin: 0,
        fontWeight: 'bold',
        fontSize: fontSizes.xl,
      },
      totalsDesktop: {
        fontSize: fontSizes.lg,
        fontWeight: 'bold',
      },
      totalStake: {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontSize: fontSizes.xl,
        marginBottom: margins.md,
        '@media (pointer: coarse)': {
          justifyContent: 'space-between',
          fontSize: fontSizes.md,
        },
      },
      totals: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        '@media (pointer: coarse)': {
          paddingLeft: 2,
        },
      },
      error: {},
    },
    IPoolsMaxSeatsAttention: {
      rootMobile: {
        background: primary,
        borderRadius: borderRadius.xl,
        margin: margins.md,
        marginBottom: margins.lg,
        padding: [margins.md, margins.xm, margins.md, margins.xm],
        color: white,
        height: 'auto',
      },
      rootDesktop: {
        background: primary,
        borderRadius: borderRadius.xl,
        margin: margins.md,
        marginBottom: 0,
        padding: [margins.md, margins.xm, margins.md, margins.xm],
        color: white,
        height: 'auto',
      },
      maxEntriesAttentionDesktop: {},
      attentionHeader: {
        padding: 0,
        margin: 0,
        fontWeight: 'bold',
        fontSize: fontSizes.xl,
      },
    },
    IPoolsSubmitChoice: {
      root: {
        background: primary,
        borderRadius: borderRadius.xl,
        margin: [20, margins.xm],
        padding: [margins.md, margins.xm],
        color: white,
        height: '91px',
        '@media (pointer: coarse)': {
          padding: margins.md,
          margin: margins.xm,
        },
      },
      choiceHeading: {
        padding: 0,
        margin: 0,
        fontWeight: 'bold',
        fontSize: fontSizes.xl,
      },
      totalStake: {
        '@media (pointer: coarse)': {
          marginBottom: 0,
          display: 'flex',
          flexDirection: 'column',
        },
      },
      totalStakeItem: {
        marginRight: margins.xm,
        '&:last-child': {
          marginRight: 0,
        },
      },
      totals: {
        fontSize: fontSizes.lg,
        fontWeight: 'bold',
      },
    },
    IPoolsLeaderboardStats: {
      root: {
        background: primary,
        borderRadius: borderRadius.xl,
        margin: margins.xm,
        padding: [margins.md, margins.xm],
        color: white,
        '@media (pointer: coarse)': {
          padding: margins.md,
          margin: margins.xm,
        },
      },
      mobileRoot: {},
      choiceHeading: {
        padding: 0,
        margin: 0,
        fontWeight: 'bold',
        fontSize: fontSizes.xl,
      },
      totalStake: {
        marginBottom: margins.md,
        '@media (pointer: coarse)': {
          marginBottom: 0,
          display: 'flex',
          flexDirection: 'column',
        },
      },
      totalStakeItem: {
        '&:last-child': {
          marginRight: 0,
        },
        flex: '1 0 50%',
        whiteSpace: 'nowrap',
      },
      row: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      mobileRow: {
        flexDirection: 'column',
      },
      totals: {
        fontSize: fontSizes.lg,
        fontWeight: 'bold',
      },
    },
    IPoolsLeaderboard: {
      root: {
        borderRadius: borderRadius.xl,
        margin: margins.xm,
        padding: [margins.md, margins.xm],
        color: white,
        background: text2,
        '@media (pointer: coarse)': {
          padding: [margins.xm, margins.xm, margins.md],
          margin: 0,
          background: neutral1,
        },
      },
      list: {},
      row: {
        display: 'flex',
        marginBottom: margins.xs,
        '&:first-child': {
          fontSize: fontSizes.xl,
          color: '#ffe764',
        },
        '&:nth-child(2)': {
          fontSize: fontSizes.lg,
          color: 'silver',
        },
        '&:nth-child(3)': {
          color: '#d2975b',
        },
      },
      icon: {
        flex: 1,
        paddingRight: margins.xm,
      },
      playerName: {
        flex: 7,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      currentPlayerRow: {
        '& > span': {
          color: primary,
        },
      },
      playerScore: {
        flex: 1,
        fontWeight: 900,
        textAlign: 'right',
      },
      playerScorePoints: {
        fontSize: fontSizes.sm,
        padding: '0 0 0 2px',
      },
      leaderboardHeading: {
        fontWeight: 900,
        fontSize: 18,
        padding: [0, 0, margins.md],
        margin: 0,
      },
      leaderboardTableHeadingsRoot: {
        display: 'flex',
        flexDirection: 'row',
      },
      leaderboardTableHeading: {
        color: text3,
        padding: [0, 0, margins.xs],
        fontSize: fontSizes.sm,
        '&:first-child': {
          flexBasis: '40px',
          '@media (pointer: coarse)': {
            flex: 1,
            paddingRight: margins.xm,
          },
        },
        '&:nth-child(2)': {
          flex: 7,
        },
        '&:last-child': {
          flex: 1,
        },
      },
      tieBreak: {
        margin: '0 0 2rem',
      },
    },
    IPoolsBars: {
      title: {
        color: text1,
        padding: [margins.md, 0],
      },
      link: {
        ...buttons.linkDefault,
        display: 'inline-block',
        fontSize: fontSizes.md,
        marginBottom: margins.lg,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
          },
        }),
      },
    },
  };
};

export type JSSThemeDefaultIPoolsType = (JSSThemeVariablesType) => $DeepShape<
  $Call<typeof JSSThemeDefaultIPools, JSSThemeVariablesType>
>;
