// @flow
import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60042: JSSThemeDefaultAccountSectionType = themeVars => {
  const {
    colors: {
      colorTypes: { background2, background5, white },
    },
    margins,
  } = themeVars;
  return {
    BetsHistory: {
      filterSelectionItem: {
        background: background5,
        '&:hover, &:active, &:focus ': {
          background: '#FFB951',
        },
      },
    },
    OPMProvider: {
      providerRoot: {
        background: white,
        border: 'none',
        boxShadow: '3px 3px 15px #e4e4e4',
        color: background2,
        maxWidth: 170,
        borderRadius: 0,
      },
      logoImg: {
        marginBottom: 10,
        opacity: '.9',
      },
    },
    OPMPageComponent: {
      heading: {
        paddingLeft: 10,
      },
    },
    OPMForms: {
      fieldWrapper: {
        maxWidth: '250px',
      },
      description: {
        '& a': {
          color: '#f93',
        },
      },
    },
    AccountPageDesktop: {
      accountPageLeftContent: {
        background: background2,
        paddingTop: margins.md,
      },
      accountPageRightContent: {
        color: background2,
      },
    },
  };
};
