// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip: JSSThemeDefaultBetslipType = (themeVars) => {
  const {
    colors: {
      colorTypes: { background2, primary, text5, white, neutral4 },
    },
    margins,
  } = themeVars;
  return {
    Betslip: {
      root: {
        background: white,
      },
      info: {
        background: 'transparent',
      },
      oneButton: {
        margin: [margins.xm, margins.md],
      },
      linkButtons: {
        color: text5,
        background: white,
      },
      message: {
        background: primary,
        color: white,
      },
    },
    BetslipTotals: {
      root: {
        borderTop: 'none',
      },
      summaryLabel: {
        paddingTop: '5px',
        color: text5,
      },
      summaryValue: {
        color: text5,
      },
      container: {
        color: text5,
      },
    },
    BetslipSelection: {
      common: {
        borderRadius: 3,
        background: neutral4,
        padding: 10,
      },
    },
    BetslipErrors: {
      list: {
        borderRadius: 0,
        background: 'transparent',
        padding: '0',
        color: '#d82e2e',
      },
      readMoreLink: {
        color: '#d82e2e',
        '&.active, &:hover': {
          color: '#d82e2e',
        },
      },
    },
    NonSingleBody: {
      selectionInfo: {
        color: text5,
      },
    },
    TextPair: {
      row: {
        color: text5,
      },
    },
    SingleSelectionInfo: {
      liveLabel: {
        background: primary,
        borderRadius: 0,
        display: 'inline-block',
        verticalAlign: 'text-top',
      },
      marketDescription: {
        color: text5,
      },
      eventDescription: {
        color: text5,
      },
      remove: {
        color: text5,
      },
      priceDescription: {
        color: text5,
      },
    },
    SingleSelectionStaticInfo: {
      eventDescription: {
        color: text5,
      },
      marketDescription: {
        color: text5,
      },
      priceDescription: {
        color: text5,
      },
    },
    BetslipInput: {
      container: {
        background: '#989EA8',
      },
      label: {
        color: white,
      },
    },
    Receipt: {
      root: {
        background: white,
      },
      controls: {
        background: white,
      },
    },
    EachWayButton: {
      root: {
        color: text5,
      },
      label: {
        '&:before': {
          border: `1px solid ${background2}`,
          background: white,
          borderRadius: '3px',
        },
        '&:after': {
          border: `2px solid ${background2}`,
        },
      },
      checked: {
        '&:after': {
          opacity: 1,
        },
      },
    },
    BetslipStakeInfo: {
      info: {
        color: text5,
      },
    },
    ShareBets: {
      message: {
        '&.shown': {
          color: text5,
        },
      },
      shareButton: {
        '&:hover' : {
          backgroundColor:  'rgb(34 85 164 / 69%);',
        },
      },
    },
  };
};
