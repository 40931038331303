// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu60042: JSSThemeDefaultDropdownMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { white, text5, black },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      selectorWrap: {
        color: white,
      },
      item: {
        background: white,
        color: text5,
        '&:hover,&.active': { color: white },
      },
    },

    dropdownMobile: {
      select: {
        color: black,
      },
      description: {
        color: black,
      },
      chevron: {
        color: black,
      },
    },
  };
};

