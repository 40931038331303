// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions60042: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { white, primary, text5 },
    },
  } = themeVars;
  return {
    PromotionsComponent: {
      itemsByType: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      itemsByTypeTitle: {
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: fontSizes.xl,
        marginBottom: margins.md,
        color: '#4e5a6c',
      },
      promoLink: {
        color: text5,
        '&:hover, &:active': {
          color: text5,
          borderBottom: `3px solid ${text5}`,
        },
      },
    },

    SeasonalPromotion: {
      promoHeader: {
        color: '#fff',
      },
      termsHeader: {
        color: '#fff',
      },
    },

    PromotionsWrapper: {
      promotionsWrapper: {
        padding: [0, margins.xm],
      },
      promotionsHeading: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        padding: [margins.lg, 0, margins.xs, 0],
      },
    },
    PromotionUI: {
      buttonSection: {
        marginTop: 'auto',
        paddingTop: 10,
      },
      promoItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        flexBasis: '100%',
        width: '100%',
        marginBottom: margins.xl - 10,
        '@media screen and (min-width: 1300px)': {
          flexBasis: `calc((100% - ${margins.xm}px) / 2)`,
          '&:nth-child(odd)': {
            marginRight: margins.xm,
          },
        },
      },
      itemBody: {
        backgroundPosition: '30% center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: white,
        overflow: 'hidden',
        padding: margins.xm,
        display: 'flex',
      },
      bannerContentWrapper: {
        display: 'flex',
        width: '295px',
      },
      itemSecondTitle: {
        color: primary,
        fontSize: fontSizes.xl,
        fontWeight: 'bold',
        marginTop: margins.md,
      },
      itemDescription: {
        fontSize: fontSizes.md,
        color: text5,
      },
    },
  };
};
