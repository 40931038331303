// @flow
import { type JSSThemeDefaultSitemapType } from '../JSSThemeDefaultSitemap';

export const JSSThemeSitemap60042: JSSThemeDefaultSitemapType = (themeVars) => {
  const {
    colors: {
      colorTypes: { text5, black },
    },
  } = themeVars;
  return {
    sitemapTitle: {
      color: text5,
    },
    firstLinkLevel: {
      color: text5,
      '&:hover': {
        color: black,
      },
    },
    secondLinkLevel: {
      color: text5,
      '&:hover': {
        color: black,
      },
    },
    thirdLinkLevel: {
      color: text5,
    },
  };
};
