// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll60042: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, primary },
    },
  } = themeVars;
  return {
    legal: {
      paddingTop: '60px',
    },
    footerWrap: {
      background: primary,
      color: white,
    },
    licenseLink: {
      paddingRight: 0,
      '&:last-child': {
        display: 'none',
      },
    },
    menuWrapper: {
      width: '100%',
      '@media screen and (max-width: 1009px)': {
        paddingBottom: '0',
      },
    },
    newStyle: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #6889c0',
      paddingBottom: 20,
      '@media screen and (max-width: 1300px)': {
        flexWrap: 'wrap',
      },
    },
    logosContainer: {
      display: 'block',
      margin: 10,
    },
    logoSvg: {
      fill: white,
      width: 89,
      maxHeight: 30,
    },
  };
};
