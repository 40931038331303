// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm60042: JSSThemeDefaultFormType = themeVars => {
  const {
    colors: {
      colorCombinations: { bg1White },
      colorTypes: { background1, text4, white },
    },
    margins,
    fontSizes,
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        border: `1px solid #c6c6c6`,
        ...bg1White,
        borderRadius: borderRadius.lg,
        lineHeight: 'auto',
        height: 'auto',
        padding: [10, 10, 10, 14],
        '&::placeholder': {
          color: '#c6c6c6',
        },
        '&:-webkit-autofill': {
          //  '-webkit-box-shadow': `0 0 0 30px ${border2} inset !important`,
          '-webkit-text-fill-color': `${background1} !important`,
        },
      },
      betslipInput: {
        height: '30px',
        lineHeight: '30px',
        borderRadius: borderRadius.md,
        border: 'none',
        background: white,
        '&::placeholder': {
          color: text4,
        },
      },
      iconShowPassword: {
        color: background1,
        opacity: 0.7,
      },
    },
    Field: {
      root: {
        color: 'black',
        marginBottom: margins.xm * 2,
      },
      fieldLabel: {
        fontSize: fontSizes.lg,
        display: 'block',
        padding: [0, 0, margins.xs],
        fontWeight: 'bold',
      },
    },
    PasswordField: {
      iconShowPassword: {
        color: background1,
        opacity: 0.5,
      },
    },
  };
};
