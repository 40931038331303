// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive60042: JSSThemeDefaultLiveType = themeVars => {
  const {
    colors: {
      colorTypes: { white, black },
    },
  } = themeVars;
  return {
    LiveMenuEvent: {
      LiveEventRanked: {
        background: white,
      },
      LiveIcon: {
        fill: '#ff9933',
      },
      TeamsText: {
        color: black,
      },
    },
    LiveMenuEventHeading: {
      LiveIcon: {
        fill: '#ff9933',
      },
    },
    LiveMenuRankedEvent: {
      LiveIcon: {
        fill: '#ff9933',
      },
    },
  };
};
