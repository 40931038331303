// @flow
import type { JSSThemeVariablesType } from '../JSSThemeDefault';

export const JSSThemeRegistration60042 = (themeVars: JSSThemeVariablesType) => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    RegistrationPageButton: {
      root: {
        color: white,
        '&:hover': {
          color: white,
          background: '#4283E7',
        },
      },
    },
  };
};
