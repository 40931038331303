// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, applyOnlyMobile } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip60042';
import { JSSThemeMyBets60042 } from './JSSThemeMyBets60042';
import { JSSThemeHeaderMobile60042 } from './JSSThemeHeaderMobile60042';
import { JSSThemeSportsMenu60042 } from './JSSThemeSportsMenu60042';
import { JSSThemeBannerItems60042 } from './JSSThemeBannerItems60042';
import { JSSThemeForm60042 } from './JSSThemeForm60042';
import { JSSThemeBonuses60042 } from './JSSThemeBonuses60042';
import { JSSThemePromotions60042 } from './JSSThemePromotions60042';
import { JSSThemeSitemap60042 } from './JSSThemeSitemap60042';
import { JSSThemeCoupons60042 } from './JSSThemeCoupons60042';
import { JSSThemeSearch60042 } from './JSSThemeSearch60042';
import { JSSThemeHeaderMenu60042 } from './JSSThemeHeaderMenu60042';
import { JSSThemeDropdownMenu60042 } from './JSSThemeDropdownMenu60042';

import { JSSThemeOutcomes60042 } from './JSSThemeOutcomes60042';
import { JSSThemeBalance60042 } from './JSSThemeBalance60042';
import { JSSThemeHeader60042 } from './JSSThemeHeader60042';
import { JSSThemeAuthForm60042 } from './JSSThemeAuthForm60042';
import { JSSThemeAccountSection60042 } from './JSSThemeAccountSection60042';
import { JSSThemeLanguageSwitcher60042 } from './JSSThemeLanguageSwitcher60042';
import { JSSTheme60042Resets } from './JSSTheme60042Resets';
import { JSSThemeCasino60042 } from './JSSThemeCasino60042';
import { JSSThemePopularLeagues60042 } from './JSSThemePopularLeagues60042';
import { JSSThemeLive60042 } from './JSSThemeLive60042';
import { JSSThemeRegistration60042 } from './JSSThemeRegistration60042';
import { JSSThemeFooterAll60042 } from './JSSThemeFooterAll60042';
import { JSSThemeOryx60042 } from './JSSThemeOryx60042';

export const colorTypes60042: ColorTypes = {
  ...colorTypes,
  primary: '#2255A4',
  text1: 'rgba(245, 245, 245, 0.6)',
  text2: '#000',
  text3: '#a94442',
  text4: '#9c9c9c',
  text5: '#4E5A6C',
  background1: '#4E5A6C',
  background2: '#4E5A6C',
  background3: '#40701E',
  background4: '#e7e7e7',
  background5: '#FF9933',
  background6: '#FF9933', // new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#FF9933',
  success: '#21a22d',
  error: '#f14d4d',
  error2: '#d82e2e',
  error3: '#d82e2e',
  warning: '#ffa500',
  inform: '#2255A4',
  neutral1: '#ccc',
  neutral2: '#eabd11',
  neutral3: '#FF9933',
  neutral4: '#F1F1F1',
  border1: '#eabb5c',
  border2: '#404040',
};

const pick = ({
  text2Bg2,
  Bg2text2,
  text2Bg1,
  whiteWarning,
  whiteAccent,
  whiteBorder2,
  text1Bg2,
  text1Bg1,
  bg2Neutral1,
  whiteInform,
  whiteNeutral3,
  bg1White,
  bg1Bg4,
  bg4Bg1,
  whitePrimary,
  whiteBg2,
  text1Border2,
  errorWhite,
  text2Accent,
  bg1Neutral1,
  neutral3Text2,
  neutral1Bg1,
  text2Primary,
  text5White,
  text2Error,
  text2Error2,
  whiteText4,
}) => ({
  text2Bg2,
  Bg2text2,
  whiteWarning,
  whiteAccent,
  whiteBorder2,
  text2Bg1,
  text1Bg2,
  text1Bg1,
  bg2Neutral1,
  whiteInform,
  whiteNeutral3,
  bg1White,
  bg1Bg4,
  bg4Bg1,
  whitePrimary,
  whiteBg2,
  text1Border2,
  errorWhite,
  text2Accent,
  bg1Neutral1,
  neutral3Text2,
  neutral1Bg1,
  text2Primary,
  text5White,
  text2Error,
  text2Error2,
  whiteText4,
});

export const colorCombinations60042 = getColorCombinations(
  colorTypes60042,
  pick
);

export const JSSThemeVariables60042: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-roboto',
      fontFamily: 'osg-roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'roboto/roboto-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
  colors: {
    colorCombinations: colorCombinations60042,
    colorTypes: colorTypes60042,
  },
};

export const JSSTheme60042: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { neutral3Text2, text2Accent },
      colorTypes: { background1, neutral4, text5, white, primary, accent },
    },
    fontSizes,
    margins,
    fonts,
  } = themeVars;
  return {
    mybets: JSSThemeMyBets60042(themeVars),
    outcomes: JSSThemeOutcomes60042(themeVars),
    betslip: betslip(themeVars),
    headerMobile: JSSThemeHeaderMobile60042(themeVars),
    promotions: JSSThemePromotions60042(themeVars),
    sitemap: JSSThemeSitemap60042(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher60042(themeVars),
    registration: JSSThemeRegistration60042(themeVars),
    footerAll: JSSThemeFooterAll60042(themeVars),
    oryx: JSSThemeOryx60042(themeVars),
    NoBetsMessage: {
      root: {
        background: white,
        color: background1,
        fontWeight: 'normal',
      },
    },
    Heading: {
      common: {
        fontSize: '18px',
        color: primary,
        fontFamily: fonts.agora,
        padding: '5px 15px 5px',
      },
    },
    Button: {
      linkSecondary: {
        color: white,
        '&:hover': {
          color: white,
          opacity: 0.7,
        },
      },
      root: {
        padding: ['1px', margins.xm, '0'],
        lineHeight: '31px',
      },
      linkAccent: {
        color: text5,
        '&:hover': {
          color: text5,
          textDecoration: 'none',
        },
      },
      primary: {
        background: accent,
        color: white,
        ...applyOnlyDesktop({
          '&:hover': {
            background: '#FFB951',
            color: white,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...text2Accent,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            background: '#FFB951',
            color: white,
          },
        },
      },
      default: {
        background: '#2255A4',
        color: white,
        '&:hover, &:active': {
          background: '#4283E7',
          color: white,
          opacity: 0.9,
        },
      },
      secondary: {
        background: '#2255A4',
        color: white,
        ...applyOnlyDesktop({
          '&:hover, &:focus': {
            background: '#4283E7',
            color: white,
            opacity: 0.9,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        },
      },
      accent: {
        background: primary,
        color: white,
        '&:hover': {
          background: primary,
          color: white,
        },
        '&:disabled': {
          background: primary,
          color: white,
          opacity: '.65',
          '&:hover, &:active': {
            background: '#4283E7',
            color: white,
            opacity: '.65',
          },
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        fontWeight: 'normal',
        boxShadow: 'none',
        background: '#f1f1f1',
        color: text5,
        padding: '1px 10px 0',
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: primary,
      },
    },
    OfflineMessage: {
      popup: {
        color: 'black',
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        color: '#4e5a6c',
      },
    },
    banners: JSSThemeBannerItems60042(themeVars),
    sportsMenu: JSSThemeSportsMenu60042(themeVars),
    form: JSSThemeForm60042(themeVars),
    bonuses: JSSThemeBonuses60042(themeVars),
    coupons: JSSThemeCoupons60042(themeVars),
    header: JSSThemeHeader60042(themeVars),
    headerMenu: JSSThemeHeaderMenu60042(themeVars),
    dropdownMenu: JSSThemeDropdownMenu60042(themeVars),
    accountSection: JSSThemeAccountSection60042(themeVars),
    popularLeagues: JSSThemePopularLeagues60042(themeVars),
    search: JSSThemeSearch60042(themeVars),
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        color: text5,
        borderTop: `1px solid ${background1}`,
      },
      stakePotential: {
        color: text5,
      },
    },
    BetslipMyBetsPanelUI: {
      singleSelectionWrapper: {
        borderTop: `1px solid ${background1}`,
      },
      panel: {
        background: white,
        padding: 0,
      },
    },
    LiveCalendar: {
      common: {
        margin: 0,
      },
      link: {
        color: primary,
        '&:hover': {
          color: white,
        },
      },
      h2: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        background: neutral4,
        color: primary,
      },
      dateTitle: {
        background: 'transparent',
        color: '#4e5a6c',
      },
      dateEvent: {
        borderBottom: `1px solid ${text5}`,
        background: 'transparent',
        color: primary,
        '&:hover': {
          cursor: 'pointer',
          color: white, // $live-menu-event-color-hover;
          background: primary, // $live-menu-event-background-color-hover
        },
      },
    },
    BetslipWithBonusMyBetsStakePotentialTotalUI: {
      info: {
        color: text5,
      },
      summaryTitle: {
        maxWidth: '40%',
      },
      potentialReturnValue: {
        maxWidth: '60%',
        fontSize: 17,
      },
    },
    balance: JSSThemeBalance60042(themeVars),
    authForm: JSSThemeAuthForm60042(themeVars),
    casino: JSSThemeCasino60042(themeVars),
    live: JSSThemeLive60042(themeVars),
    resets: JSSTheme60042Resets(themeVars),
  };
};

export const getJSSThemePatterns60042: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: {
        text2Accent,
        text2Primary,
        neutral3Text2,
        text2Error2,
      },
      colorTypes: { white, neutral3, error },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: {
        ...text2Primary,
        ...applyOnlyDesktop({
          '&:hover': {
            background: '#FFB951',
            color: white,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...text2Accent,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            background: '#FFB951',
            color: white,
          },
        },
      },
      secondary: {
        ...neutral3Text2,
        ...applyOnlyDesktop({
          '&:hover, &:focus': {
            background: white,
            color: neutral3,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        }),
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            ...neutral3Text2,
          },
        },
      },
      accent: {
        ...text2Error2,
        '&:hover': {
          ...text2Error2,
        },
        '&:disabled': {
          background: error,
          color: white,
          opacity: '.65',
          '&:hover, &:active': {
            background: error,
            color: white,
            opacity: '.65',
          },
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
