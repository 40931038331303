// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader60042: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    colors: {
      colorTypes: { primary },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: primary,
      },
      authContentWrap: {
        justifyContent: 'flex-start',
      },
      authWrapper: {
        padding: 0,
      },
    },
    LogoBrand: {
      logoLink: {
        height: 37,
      },
      logoWrapper: {
        '@media screen and (max-width: 1100px)': {
          width: 150,
        },
      },
    },
  };
};
